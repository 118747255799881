@import "~@verified/veform/themes/default/style.scss";

/* cyrillic-ext */

body{
	font-family: 'Futura', sans-serif;
	font-size: 14px;
}
h1,h2,h3,h4,h5,h6{
	font-weight: bold;
}
.main {
	margin-top: 40px;
	max-width: 800px !important;
}
.btn-plus {
	background: black;
	display: inline-block;
	border-radius: 50%;
	color: #fff;
	padding: 6px;
	font-size: 26px;
	line-height: 15px;
	&:hover {
		cursor: pointer;
		background: #1c74aa;
	}
	margin: -5px 5px;
}

.btn-minus {
	background: black;
	display: inline-block;
	border-radius: 50%;
	color: #fff;
	padding: 6px 9px;
	font-size: 26px;
	line-height: 15px;
	&:hover {
		cursor: pointer;
		background: #1c74aa;
	}
	margin: -5px 5px;
}

.btn-minus-gray {
	background: #c1c1c1 !important;
	&:hover {
		cursor: not-allowed !important;
	}
}
.card{
	background: rgba(255, 255, 255, 0.75)
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle{
	background-color: black;
    border-color: black;
}
.btn-primary{
	background-color: black;
    border-color: black;
}
.btn-primary:hover{
	background-color: black;
    border-color: black;
}
.steps ul li.current span{
	color: black;
    border: 1px solid black;
}
.steps ul li.complete span{
	background-color: black;
    border-color: black;
}
.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	z-index: 1;
  }
  .smooth,
  .smooth * {
	transition: all 0.5s ease;
  
	&.delay,
	& .delay {
	  transition-delay: 0.5s;
	}
  }

  .form-control::placeholder {
	font-style: italic;
  }
    ul.languages { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-direction: normal; -moz-box-direction: normal; -webkit-box-orient: horizontal; -moz-box-orient: horizontal; -webkit-flex-direction: row; -ms-flex-direction: row; flex-direction: row; -webkit-box-pack: flex-end; -moz-box-pack: flex-end; -webkit-justify-content: flex-end; -ms-flex-pack: flex-end; justify-content: flex-end; -webkit-box-pack: end; -moz-box-pack: end; -ms-flex-pack: end; -webkit-flex-wrap: nowrap; -moz-flex-wrap: nowrap; -ms-flex-wrap: nowrap; flex-wrap: nowrap; position: absolute; width: 100%; padding: 0; top: 0; max-width: 920px; margin: 0 0 0 -40px; list-style: none; background: #F3F3F3; }
ul.languages li a { display: inline-block; padding: 10px 7px; font-size: 0px; opacity: 0.8; }
ul.languages li a:hover { opacity: 1; }
ul.languages li a:hover .flag { -webkit-box-shadow: 0 0 8px rgba(66, 66, 66, 0.4); -moz-box-shadow: 0 0 8px rgba(66, 66, 66, 0.4); box-shadow: 0 0 8px rgba(66, 66, 66, 0.4); }
ul.languages li:last-child a { padding-right: 0; }

b{
	font-weight: bold!important;
}
.form1{
	font-size: 14px;
}
.bg-primary{
	background-color: black!important;
}