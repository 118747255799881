<template>
  <div id="cover">
    <div class="loader smooth" :style="{ opacity: loading ? 1 : 0 }">
      <img :src="'./loading.gif'" />
    </div>
    <ve-header v-if="!loading">
      <img :src="'./logo.png'" height="32px">
      <div style="color:white;font-size: 11px;">{{ud.company.name}}<br>
          {{ud.company.address}}<br>
          {{ud.company.phone}}<br>
          <span v-html="ud.company.email"></span><br>
          <span v-html="ud.company.web"></span>
      </div>
    </ve-header>
    <div class="container main" v-if="!loading && !sent">
      <ve-row>
        <ve-col>
          <!-- #region form1 -->
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <ve-row>
                <ve-col md12 style="text-align:center;">
                  <!--<img
                    :src="'./logo.png'"
                    style="margin-right: 10px;padding-bottom:5px;width: 120px;"
                  />-->
                </ve-col>
                <ve-col md12 style="text-align:center;">
                  <ve-headline :headline="__('title')+' '+ud.contractDetails.roomType" style="line-height: 4;" size="2em"></ve-headline>
                </ve-col>
              </ve-row>
              <ve-custom-stepper
                :steps="steps()"
                :step="step"
                @step-click="previousStep"
                @btn-next-click="nextStep"
                 @btn-back-click="backStep"
              ></ve-custom-stepper>
              <br />
              <br />
              <ve-form v-show="step == 1" ref="form1" class="ml-3 form1">
                 <div>
                  <ve-row>
                    <ve-col xs12 sm6>
                      <ve-form-group>
                        <!--<label>{{__('signingMethod')}}</label>-->
                        <ve-select
                          :searchable="false"
                          v-model="ud.contractDetails.location"
                          @on-select="function(option){ud.contractDetails.location = option.value}"
                          :options="[
                                    {value:__('placeOp1'), text:__('placeOp1')},
                                    {value:__('placeOp2'), text:__('placeOp2')},
                                    {value:__('placeOp3'), text:__('placeOp3')},
                                    {value:__('placeOp4'), text:__('placeOp4')}
                                  ]"
                          :readonly="true"
                          rules="required"
                        />
                      </ve-form-group>
                    </ve-col>
                     <ve-col xs12 sm6>
                      <ve-form-group>
                        <!--<label>{{__('signingMethod')}}</label>-->
                        <ve-select
                          :searchable="false"
                          v-model="ud.contractDetails.roomType"
                          @on-select="function(option){ud.contractDetails.roomType = option.value}"
                          :options="[
                                    {value:__('typeOp1'), text:__('typeOp1')},
                                    {value:__('typeOp2'), text:__('typeOp2')},
                                    {value:__('typeOp3'), text:__('typeOp3')},
                                    {value:__('typeOp4'), text:__('typeOp4')}
                                  ]"
                          :readonly="true"
                          rules="required"
                        />
                      </ve-form-group>
                    </ve-col>
                  </ve-row>
                  <br>
                  <h5 v-if="ud.contractDetails.roomType!=__('typeOp4')">{{__('amountHead')}}</h5>
                  <ve-row v-if="ud.contractDetails.roomType!=__('typeOp4')">
                      <p style="font-size: 14px; padding-top: 10px;padding-left: 5px;">
                              {{__('amountInfo1')}}</p>
                            <ve-input v-model="ud.contractDetails.monthlyPrice" rules="required" 
                              style="width: 25%; margin-left: 10px; " ></ve-input>
                      <!--<ve-col xs12 sm12>-->
                        <!--<ve-form-group>-->
                            <p style="font-size: 14px; padding-top: 10px;padding-left: 5px;">
                              {{__('amountInfo2')}}</p>
                        <!--</ve-form-group>-->
                      <!--</ve-col>-->
                  </ve-row>
                  <br v-if="ud.contractDetails.roomType!=__('typeOp4')"> 
                  <h5 v-if="ud.contractDetails.roomType!=__('typeOp4')">{{__('tacHead')}}</h5>
                  <p v-if="ud.contractDetails.roomType!=__('typeOp4')&&ud.contractDetails.roomType!=__('typeOp3')" v-html="__('tacInfo')" style=""></p>
                  <p v-if="ud.contractDetails.roomType==__('typeOp3')" v-html="__('tacInfo2')" style=""></p>
                  <br v-if="ud.contractDetails.roomType!=__('typeOp4')">
                  <h5 v-if="ud.contractDetails.roomType!=__('typeOp4')">{{__('localRentHead')}}</h5>
                  <div v-if="ud.contractDetails.roomType!=__('typeOp4')">
                    <ul>
                      <li v-if="ud.contractDetails.location!=__('placeOp3')">{{__('localRentInfo1')}}</li>
                      <li v-if="ud.contractDetails.location!=__('placeOp3')">{{__('localRentInfo2')}}</li>
                      <li v-if="ud.contractDetails.location==__('placeOp3')">{{__('place3LocalRentInfo1')}}</li>
                      <li v-if="ud.contractDetails.location==__('placeOp3')">{{__('place3LocalRentInfo2')}}</li>
                      <li><ve-select
                              :searchable="false"
                              v-model="ud.contractDetails.numberOfKeys"
                              :options="[1,2,3,4,5,6,7,8,9 ]"
                              :readonly="true"
                              style="width:10%;padding-right: 5px;float: left;"
                              rules=""
                            />
                            <p style="font-size: 14px; padding-top: 10px;line-height: 30px;" 
                            v-html="ud.localRentInfo3"></p>
                      </li>
                    </ul>
                  </div>
                  <h5 v-if="ud.contractDetails.roomType==__('typeOp4')">{{__('sec1Head')}}</h5>
                  <ve-row v-if="ud.contractDetails.roomType==__('typeOp4')">
                        <!--<ve-form-group>-->
                          <div>
                          <ve-select
                              :searchable="false"
                              v-model="ud.contractDetails.numberOfKeys"
                              :options="[1,2,3,4,5,6,7,8,9 ]"
                              :readonly="true"
                              style="width:10%;padding-right: 5px;float: left;"
                              rules=""
                            />
                            <p style="font-size: 14px; padding-top: 10px;line-height: 30px;" 
                            v-html="ud.localRentInfo3"></p>
                          </div>
                        <!--</ve-form-group>-->
                      <!--</ve-col>-->
                  </ve-row>
                  <br v-if="ud.contractDetails.roomType==__('typeOp4')">
                   <h5 v-if="ud.contractDetails.location!=__('placeOp3')">{{__('sec2Head')}}</h5>
                   <h5 v-if="ud.contractDetails.location==__('placeOp3')">{{__('place3Sec2Head')}}</h5>
                   <p v-if="ud.contractDetails.location!=__('placeOp3')" v-html="__('sec2Info')" style="padding-left: 20px;"></p>
                    <p v-if="ud.contractDetails.location==__('placeOp3')" v-html="__('place3Sec2Info')" style="padding-left: 20px;"></p>
                   <br>
                   <h5>{{__('other')}}</h5>
                   <p v-html="__('otherInfo')"></p>
                </div>
              </ve-form>
              <ve-form v-show="step == 2" ref="form2" class="ml-3">
                <div>
                  <ve-row>
                        <ve-input-field 
                          md4
                          xs12
                          :label="__('ssn')"
                          :placeholder="__('ssnPlaceholder')"
                          mask="########-####"
                          type="text"
                          v-model="ud.contactPerson.personNr"
                          required
                          rules="required"
                        />
                        <ve-input-field
                          md4
                          xs12
                          :label="__('firstName')"
                          type="text"
                          v-model="ud.contactPerson.givenName"
                          required
                          rules="required"
                        />
                        <ve-input-field
                          md4
                          xs12
                          :label="__('lastName')"
                          type="text"
                          v-model="ud.contactPerson.familyName"
                          required
                          rules="required"
                        />
                         <ve-input-field
                          md6
                          xs12
                          :label="__('address')"
                          v-model="ud.contactPerson.postAddress"
                          required
                          rules="required"
                        />
                        <ve-input-field
                          md2
                          xs6
                          :label="__('zip')"
                          v-model="ud.contactPerson.zip"
                          required
                          rules="required"
                          mask="### ##"
                        />
                        <ve-input-field
                          md4
                          xs6
                          :label="__('city')"
                          v-model="ud.contactPerson.city"
                          required
                          rules="required"
                        />
                        <ve-input-field
                      md6
                      xs12
                      :label="__('email')"
                      type="email"
                      v-model="ud.contactPerson.email"
                      required
                      rules="required|email"
                    />
                    <ve-input-field
                      md6
                      xs12
                      :label="__('phone')"
                      type="text"
                      v-model="ud.contactPerson.mobile"
                      required
                      rules="required"
                    />
                  </ve-row>
                  <ve-row >
                      <ve-col md12 xs12>
                        <ve-form-group>
                          <ve-label
                          :label="__('fromDate')"
                          required
                          />
                          <datepicker v-model="ud.rentStart"
                            name="datepicker_rent_start"
                            id="datepicker-rent-start"
                            required
                            format="yyyy.MM.dd"
                            :language="sv"
                            :monday-first="true"
                            :disabled-dates="disabledStartDates"
                            :input-class="'calendar-icon form-control' +(isSubmit? (ud.rentStart ? ' is-valid' : ' is-invalid'):'')"
                          ></datepicker>
                          <div class="invalid-feedback" v-if="showInvalidFeedback['datepicker-rent-start']"
                              :style="{ display: ud.rentStart ? 'none' : 'block' }">
                            {{ __('required') }}
                          </div>
                        </ve-form-group>
                      </ve-col>
                      <!--<ve-col md6 xs12>
                        <ve-form-group>
                          <ve-label
                          :label="__('toDate')"
                          required
                          />
                          <datepicker v-model="ud.rentEnd"
                            name="datepicker_rent_end"
                            id="datepicker-rent-end"
                            required
                            format="yyyy.MM.dd"
                            :monday-first="true"
                            :language="sv"
                            :disabled-dates="disabledEndDates"
                            :input-class="'calendar-icon form-control' +(isSubmit?  (ud.rentEnd ? ' is-valid' : ' is-invalid'):'')"
                          ></datepicker>
                          <div class="invalid-feedback" v-if="showInvalidFeedback['datepicker-rent-end']"
                              :style="{ display: ud.rentEnd ? 'none' : 'block' }">
                            {{ __('required') }}
                          </div>
                        </ve-form-group>
                      </ve-col>-->
                  </ve-row>
                  <ve-row>
                    <ve-input-field
                          md4
                          xs6
                          :label="__('bandName')"
                          v-model="ud.contractDetails.bandName"
                          required
                          type="text"
                          rules="required"
                        />
                        <ve-input-field
                      md4
                      xs12
                      :label="__('bandNo')"
                      type="text"
                      v-model="ud.contractDetails.numberOfMembers"
                      required
                      rules="required"
                    />
                    <ve-input-field
                      md4
                      xs12
                      :label="__('localNo')"
                      type="text"
                      v-model="ud.contractDetails.roomNumber"
                      required
                      rules="required"
                    />
                  </ve-row>
                </div>
              </ve-form>
              <ve-form v-show="step == 3" ref="form3" class="ml-3">
                <div>
                  <h3 v-if="ud.contractDetails.roomType!=__('typeOp4')">{{ud.conditionsHead}}</h3>
                  <h5 v-if="ud.contractDetails.roomType!=__('typeOp4')">{{__('paymentHead')}}</h5>
                   <p v-if="ud.contractDetails.roomType!=__('typeOp4')" v-html="__('paymentInfo')" ></p>
                   <br v-if="ud.contractDetails.roomType!=__('typeOp4')">
                   
                  <h5>{{__('sec3Head')}}</h5>
                   <p v-html="__('sec3Info')" ></p>
                   <br>
                   <h5 v-if="ud.contractDetails.location!=__('placeOp3')">{{__('alarmHead')}}</h5>
                   <p v-if="ud.contractDetails.location!=__('placeOp3')" v-html="__('alarmInfo')" ></p>
                   <br>
                   <!--<h5>{{__('sec4Head')}}</h5>
                   <p v-html="__('sec4Info')"></p>
                   <br>-->
                   <h5 v-if="ud.contractDetails.roomType!=__('typeOp4')">{{__('obligHead')}}</h5>
                   <p v-if="ud.contractDetails.roomType!=__('typeOp4')&&ud.contractDetails.location!=__('placeOp3')" v-html="__('obligInfo')" ></p>
                   <p v-if="ud.contractDetails.roomType!=__('typeOp4')&&ud.contractDetails.location==__('placeOp3')" v-html="__('place3ObligInfo')" ></p>
                   <br v-if="ud.contractDetails.roomType!=__('typeOp4')">
                   <p v-html="__('info')"></p>
                </div>
              </ve-form>
              <div >
                  <br>
                  <br>
                  <ve-row >
                    <ve-col v-if="step!=1">
                      <button style="float:left;" class="btn btn-rounded btn-primary te" type="button" @click="backStep()">{{__('back')}}</button>
                    </ve-col>
                    <ve-col v-if="step==3">
                      <button style="float:right;" class="btn btn-rounded btn-primary te" type="button" @click="submit()">{{__('forward')}}</button>
                    </ve-col>
                    <ve-col v-if="step!=3">
                      <button style="float:right;" class="btn btn-rounded btn-primary te" type="button" @click="nextStep()">{{__('continue')}}</button>
                    </ve-col>
                  </ve-row>
                </div>
            </div>
          </div>
          <!-- #endregion -->
        </ve-col>
      </ve-row>
    </div>
  </div>
</template>

<script>
export { default } from "./script.js";
</script>
<style>
.inline-input {
  display: inline;
}

.inline-input > input {
  display: inline;
  width: 100px;
}
#cover {
  width: 100%;
  overflow: scroll;
  height: 100%;
  position: absolute;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}
</style>